import React from 'react';
import { FaGithub, FaExternalLinkAlt } from 'react-icons/fa'; // Import icons
import ProjectCarousel from './ProjectCarousel';
import './styles/Projects.css';

function Projects() {
  const projects = [
    {
      title: 'Music Project',
      description: "Search through a database of a user's listening history",
      liveLink: 'https://music.seanmburns.com',
      githubLink: 'https://github.com/seanburnzs/musicMuse',
      screenshots: [
        '/project-screenshots/home.png', 
        '/project-screenshots/query.png', 
        '/project-screenshots/streamingHistory.png'
      ]
    },
    {
      title: 'Poetry Project',
      description: 'Read and share poetry with other users',
      liveLink: 'https://poetry.seanmb.live',
      githubLink: 'https://github.com/seanburnzs/poetryProject',
      screenshots: [
        '/project-screenshots/poemBox.png',
        '/project-screenshots/submitPoem.png',
        '/project-screenshots/userProfile.png'
      ]
    }
    // Other Projects
  ];

  return (
    <div className="projects-section">
      <h2>Projects</h2>
      <div className="projects-grid">
        {projects.map((project, index) => (
          <div key={index} className="project-card">
            <h3>{project.title}</h3>
            <ProjectCarousel screenshots={project.screenshots} />
            <div className="text-container">
              <p>{project.description}</p>
            </div>
            <div className="project-links">
              {project.githubLink && (
                <a href={project.githubLink} target="_blank" rel="noopener noreferrer" aria-label="GitHub Repository">
                  <FaGithub size={24} />
                </a>
              )}
              {project.liveLink && (
                <a href={project.liveLink} target="_blank" rel="noopener noreferrer" aria-label="Live Project">
                  <FaExternalLinkAlt size={22} />
                </a>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Projects;
