import React from 'react';
import './styles/Footer.css';

function Footer() {
  return (
    <footer className="footer">
      <p>Build and design by Sean Burns</p>
      <p>All rights reserved ©</p>
    </footer>
  );
}

export default Footer;