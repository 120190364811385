import React, { useState } from 'react';
import './styles/Projects.css';

function ProjectCarousel({ screenshots }) {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => 
      (prevIndex + 1) % screenshots.length
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => 
      prevIndex === 0 ? screenshots.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="project-carousel">
      <div className="carousel-image-container">
        {screenshots.map((screenshot, index) => (
          <img
            key={index}
            src={screenshot}
            alt={`Project screenshot ${index + 1}`}
            className={`carousel-image ${index === currentIndex ? 'active' : ''}`}
          />
        ))}
      </div>
      
      {screenshots.length > 1 && (
        <>
          <button className="carousel-prev" onClick={prevSlide} aria-label="Previous image">
            &#10094;
          </button>
          <button className="carousel-next" onClick={nextSlide} aria-label="Next image">
            &#10095;
          </button>
        </>
      )}
      
      <div className="carousel-indicators">
        {screenshots.map((_, index) => (
          <span 
            key={index} 
            className={`indicator ${index === currentIndex ? 'active' : ''}`}
            onClick={() => setCurrentIndex(index)}
          />
        ))}
      </div>
    </div>
  );
}

export default ProjectCarousel;