import React from 'react';
import './styles/About.css';

function About() {
  const technologies = [
    'Python',
    'SQL',
    'JavaScript',
    'C#',
    'C++',
    'HTML & CSS',
  ];

  return (
    <div className="about-section">
      <h2>About Me</h2>
      
      <div className="about-content">
        <div className="text-container">
          <h3>Technical Skills</h3>
          <p>These are the technologies I've worked with most prominently:</p>
          
          <div className="technologies-grid">
            {technologies.map((tech, index) => (
              <div key={index} className="technology-item">
                <span className="bullet">▹</span> {tech}
              </div>
            ))}
          </div>
        </div>
      </div>
      
      <div className="text-container interests-container">
        <h3>Interests</h3>
        <p>
          Beyond technology, I'm passionate about music, sports, history, and politics.
        </p>
      </div>
    </div>
  );
}

export default About;