import React, { useState, useEffect } from 'react';
import './styles/NavBar.css';

function NavBar() {
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    section.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <nav className={`navbar ${isSticky ? 'sticky' : ''}`}>
      <div className="navbar-left">
        <h1 className="site-name">Sean Burns</h1>
        <div className="nav-buttons">
          <button onClick={() => scrollToSection('home')}>Home</button>
          <button onClick={() => scrollToSection('about')}>About</button>
          <button onClick={() => scrollToSection('projects')}>Projects</button>
        </div>
      </div>
      <div className="navbar-right">
        <div className="social-icons">
          <a href="mailto:contact@seanmburns.com" aria-label="Email">
            <i className="icon-email"></i>
          </a>
          <a href="https://linkedin.com/in/seanmburns" aria-label="LinkedIn">
            <i className="icon-linkedin"></i>
          </a>
          <a href="https://github.com/seanmburns" aria-label="GitHub">
            <i className="icon-github"></i>
          </a>
        </div>
      </div>
    </nav>
  );
}

export default NavBar;