import React from 'react';
import { FaGithub, FaLinkedin, FaEnvelope, FaSpotify } from 'react-icons/fa';
import './styles/SocialLinks.css';

function SocialLinks({ variant }) {
  const isNavbar = variant === 'navbar';
  const iconSize = isNavbar ? 20 : 24; // Slightly bigger icons for About page
  
  return (
    <div className={`social-links ${variant}`}>
      <a href="https://github.com/seanburnzs" target="_blank" rel="noopener noreferrer" aria-label="GitHub">
        <FaGithub size={iconSize} />
      </a>
      <a href="https://www.linkedin.com/in/seanburnzs" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
        <FaLinkedin size={iconSize} />
      </a>
      <a href="mailto:seanburnzss@gmail.com" aria-label="Email">
        <FaEnvelope size={iconSize} />
      </a>
      <a href="https://open.spotify.com/user/21bnobqzbheqob4u24qa5rlwq?si=edb0c2d685274b17" target="_blank" rel="noopener noreferrer" aria-label="Spotify">
        <FaSpotify size={iconSize} />
      </a>
    </div>
  );
}

export default SocialLinks;
