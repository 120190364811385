import React from 'react';
import SocialLinks from './SocialLinks';
import './styles/Home.css';

function Home() {
  return (
    <div className="home-section">
      <div className="home-content">
        <div className="home-text">
          <h1>Hi! I'm Sean Burns</h1>
          <div className="text-container">
            <p>
              I am currently a University of Houston Victoria senior graduating in May with a Bachelor 
              of Science in Computer Information Systems. Pursuing a Master of Science 
              in Computer Science or Data Science beginning in the Fall.
            </p>
          </div>
        </div>
        <div className="home-image">
          <img 
            src="/assets/profile.jpg"
            alt="Sean Burns"
            className="profile-picture"
          />
        </div>
      </div>
      <SocialLinks variant="reach-out" />
    </div>
  );
}

export default Home;
